.survey-form {
    padding: 0;
    
    @include media-breakpoint-up(md) {
        padding: 1em 4em;
    }

    .logo {
        max-width: 180px;
        margin: auto;
        display: block;
        margin: 1em auto;

        @include media-breakpoint-up(md) {
            margin: 2em auto;
            max-width: 300px;
        }
    }

    textarea {
        height: 80px;
        resize: none;

        @include media-breakpoint-up(md) {
            height: 120px;
        }
    }

    .send-btn {
        margin: auto;
        letter-spacing: 1px;
        padding: 0.5em 1.8em;
        display: block;
    }

    label {
        color: #707070;
        letter-spacing: 1px;
    }
}

.survey-result {
    display: none;

    .text-danger + img {
        display: none;
    }
}

#survey.result {
    .survey-result {
        display: block;
    }

    .survey-form {
        display: none;
    }
}

.rating {
    // display: inline-block;
    margin-bottom: 1.5em;

    @include media-breakpoint-up(md) {
        margin-bottom: 2.5em;
    }

    .rating-title {
        color: $brand-green;
        letter-spacing: 1px;
        font-size: 1.1em;
    }

    .rating-box {
        display: inline-block;
    }

    .star {
        cursor: pointer;

        svg {
            width: 3em;
            height: 3em;
        }

        &.active {
            svg path {
                fill: $brand-green;
            }
        }

        &.hover {
            svg path {
                fill: #009645E6;
            }
        }
    }
}