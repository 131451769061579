.header {
    background-color: $brand-green;
    position: sticky;
    top: 0;
    z-index: 100;

    .logo {
        max-width: 80px;

        @include media-breakpoint-up(md) {
            max-width: 140px;
        }
    }
    
    .social-link {
        width: 110px;
    }

    .nav li a{
        padding: 0.5em 1em;
        border-radius: 25px;
        background: #fff;
        color: $body-color;
        font-size: 0.8em;

        &:hover {
            color: $brand-green;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            color: #fff;
            background-color: initial;
            padding: 0;
            font-size: 1em;

            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}

.banner-section {
    position: relative;
    
    img {
        height: 30vh;
        object-fit: cover;
        width: 100%;
        object-position: center;

        @include media-breakpoint-up(md) {
            height: 25vw;
        }
    }

    &:before {
        content: '';
        position: absolute;
        width: 80%;
        height: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent linear-gradient(90deg, #CAD0D400 0%, #ffffffe6 60%, #fff 100%) 0% 0% no-repeat padding-box;
    }   

    &:after {
        content: '';
        background-image: url('/images/slogan.png');
        position: absolute;
        top: 0;
        bottom: 0;
        width: 23vw;
        height: 100%;
        right: 5vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.section {
    margin-top: 4em;
    margin-bottom: 2em;

    @include media-breakpoint-up(md) {
        margin-top: 5em;
        margin-bottom: 2em;
    }

    .section-title {
        color: $brand-green;
        font-size: 1.5em;
        margin-bottom: 1em;

        @include media-breakpoint-up(md) {
            font-size: 2.2em;
        }
    }
}

footer {
    background-color: $brand-grey;

    .top-bar {
        padding: 2em 0 2.5em;

        p {
            margin: 0;
        }
    }
    
    .bottom-bar {
        color: #fff;
        background-color: $brand-green;
        font-size: 14px;
        padding: 16px 0;
    }
}

.order-section {
    margin: 0;
    margin-top: 2em;
    margin-bottom: 5em;
    background-color: $brand-grey;
    padding-top: 1em;

    @include media-breakpoint-up(md) {
        margin: 4em auto;
        background-color: transparent;
    }
}

button.close{
    position: relative;
    z-index: 9999;
  
    img {
        width: 15px;
    }
}

.modal-body {
    background-image: url(/images/h.png);
    background-size: 20px;
    background-position: bottom 15px right 15px;
    background-repeat: no-repeat;
}
