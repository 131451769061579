// HelveticaNeueLTGEO-65Medium.otf
// HelveticaNeueLTGEO-35Thin.otf
// HelveticaNeueLTGEO-45Light.otf
// HelveticaNeueLTGEO-55Roman.otf

@include font('HelveticaNeueLTGEO', '/fonts/helvetica/HelveticaNeueLTGEO-35Thin', 100);
@include font('HelveticaNeueLTGEO', '/fonts/helvetica/HelveticaNeueLTGEO-45Light', 300);
@include font('HelveticaNeueLTGEO', '/fonts/helvetica/HelveticaNeueLTGEO-55Roman');
@include font('HelveticaNeueLTGEO', '/fonts/helvetica/HelveticaNeueLTGEO-65Medium', 500);

body {
    font-family: 'HelveticaNeueLTGEO';
    font-size: 14px;
}

.caps {
    -webkit-font-feature-settings: "case" on;
    font-feature-settings: "case" on;
}