.product-box {
    text-align: center;    
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;


    .image {
        padding: 1em;
        background-color: $brand-grey;
        border-radius: $default-border-radius;
        display: inline-block;
        flex: 1;
        transition: 0.3s ease-out;

        &:hover {
            box-shadow: 0px 2px 6px 0px #1b8dbb59;
        }
    }

    .title {
        margin-top: 0.8em;
        margin-bottom: 1em;
        font-size: 1.2em;
    }
}

.product-page {
    --main-color: #1e90ff;
    --text-color: #ffffff;
    
    color: var(--main-color);

    .cover {
        margin-top: 4em;

        img {
            border-radius: $default-border-radius;
            width: 100%;
            object-fit: cover;
        }
    }

    .title {

    }

    video {
        max-width: 600px;
        width: 100%;
    }

    .recipes {
        h3 {
            letter-spacing: 2px;
        }
    }

    .download-pdf {
        text-decoration: none;
        
        span {
            display: inline-block;
            position: relative;
        }

        span:after {
            content: '';
            height: 1px;
            width: 0%;
            display: inline-block;
            background-color: $brand-green;
            position: absolute;
            left: 50%;
            // right: 0;
            bottom: -5px;
            transition: .3s;
        }

        &:hover {
            span:after {
                width: 100%;
                left: 0;
            }
        }

        img {
            width: 35px;
            margin-right: 1em;
        }
    }
}

.recipe-block {
    border: 1px solid;
    border-radius: $default-border-radius;
    padding: 24px 16px;
    box-shadow: 1px 1px 3px #DEDBDB;
    height: 100%;
    transition: 0.3s;
    
    svg {
        fill: var(--main-color);
        stroke: var(--main-color);
        transition: 0.3s;
    }

    &:hover {
        background-color: var(--main-color);
        color: var(--text-color);

        svg {
            fill: var(--text-color);
            stroke: var(--text-color);
        }
    }

    .title {
        font-weight: 600;
        margin: 0;
    }

    .subtitle {
        font-size: 12px;
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 1em;
        display: inline-block;
    }

    .recipe-element {
        display: flex;

        
        &:not(:last-child) {
            margin-bottom: 0.5em;
        }

        div {
            margin-top: 0.3em;
        }

        p:first-child {
            font-size: 1.2em;
            line-height: 1.3em;
            font-weight: 600;
        }

        sup {
            font-size: 0.6em;
            font-weight: 400;
            top: -1.3em;
        }

        .icon {
            border: 3px solid;
            width: 50px;
            height: 50px;
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;

            svg {
                margin: auto;

                .fill{
                    fill:inherit;
                    fill-rule:evenodd;
                    stroke: none;
                }

                .stroke{
                    stroke: inherit;
                    fill:none;
                    stroke-miterlimit:10;
                    stroke-width:3px;
                }
            }
        }

        p {
            margin: 0;
        }
    }
}

.product-information {
    margin-top: 4em;
    width: 90%;
    margin: 4em auto 2em;
}