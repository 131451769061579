.order-form {
    // background-color: $brand-grey;
    padding: 0 0 1em;
    
    @include media-breakpoint-up(md) {
        border-radius: $default-border-radius;
        padding: 4em 2em;
        background-color: $brand-grey;
    }

    .add-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 10px;
            margin-right: 10px
        }

        span {
            margin-top: 1px;
            font-size: 0.8em;
            line-height: 1.8em;

            @include media-breakpoint-up(md) {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    label {
        font-size: 0.9em;

        @include media-breakpoint-up(md) {
            font-size: 1em;
        }
    }

    .bulk-cement .form-text {
        position: absolute;
        bottom: -2em;
        width: 16em;
    }
}

.cart {
    margin-top: 1em;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    .cart-list {
        @extend .list-unstyled;
    }

    .cart-product {
        display: flex;
        justify-content: space-between;
        color: #545454;
        align-items: center;

        .title {
            margin-right: auto;
        }

        .remove {
            margin-left: 0.5em;
            margin-right: -0.5em;
            padding: 0.5em;

            img {
                pointer-events: none;
            }
            
            &:hover {
                opacity: 0.8;
            }
        }
    }

    .total {
        color: $brand-green;
        font-weight: 500;
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-top: 0.5em;
        font-size: 1.1em;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            width: 100%;
            top: 0;
            height: 2px;
            background: $brand-green;
        }
    }
}


#orderSurvey{
    &.result  {
        .order-survey-result {
            display: block;
        }
    
        .order-survey-form {
            display: none;
        }   
    }

    .order-survey-result {
        display: none;
    }

    textarea {
        height: 120px;
        resize: none;
    }

    &.success {
        .result-title {
            color: $brand-green;
        }
    }

    &.error {
        .result-title {
            color: $red;
        }

        .result-survey {
            display: none;
        }
    }

    .order-survey-result {
        display: none;
    
        .text-danger + img {
            display: none;
        }
    }
}