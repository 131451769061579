// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #FF4F00;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;


//Heidelberg 
$brand-green: #009645;
$brand-grey: #F3F3F3;

$primary: $brand-green;
$default-border-radius: 20px;

$btn-border-radius: $default-border-radius;
$btn-border-radius-lg: $default-border-radius;
$btn-font-size: 12px;
$btn-font-size-lg: 12px;

$input-border-radius: $default-border-radius;
$input-border-radius-lg: $default-border-radius;
$input-font-size: 12px;
$input-font-size-lg: 12px;
$input-focus-border-color: $blue;
// $input-focus-box-shadow: $blue;
$enable-validation-icons: false;

$modal-content-bg: #F9F9F9;
$modal-content-border-radius: $default-border-radius;