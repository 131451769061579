@mixin font($font-family, $font-file, $font-weight: 'normal', $font-style: 'normal') {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.otf');
        src: url($font-file+'.otf') format('opentype'),
            url($font-file+'.woff') format('woff'),
            url($font-file+'.ttf') format('truetype'),
            url($font-file+'.svg#'+$font-family) format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
